<template>
    <div class="putInto">
        <div class="siteInfoDiv">
            <h5>站点规范</h5>
            <van-uploader
                    v-model="checkFile"
                    disabled
                    :deletable="false"
                    accept="image"
                    :show-upload = false
            />
        </div>
            <van-pull-refresh v-model="isLoadingState"
                              @refresh="stockOnRefresh">
                <van-list
                        v-model="stateLoading"
                        :finished="stateFinished"
                        finished-text="没有更多了"
                        @load="stateOnLoad"
                >
                    <div class="public-card state-padding m-b-15"
                         v-for="(item,idx) in checkDetail" :key="idx"
                    >
                        <div class="public-flex public-just-between m-t-15">
                            <div class="state-info">
                                <div class="m-t-10">检查人: {{item.inspector}}</div>
                                <div class="m-t-10">检查类型: {{item.checkTypeName}}</div>
                                <div class="m-t-10">检查时间: {{item.checkTime}}</div>
                            </div>
                            <div  class="state-num"  @click="questionDetail(item.taskNo,item.checkType)">{{item.score}}</div>
                        </div>
                    </div>
                </van-list>

            </van-pull-refresh>

            <van-dialog v-model="questionListShow" title="问题列表"
                        :showConfirmButton="false"
                        :closeOnClickOverlay="true"
                        style="height:650px;overflow-y:auto"
            >
                <van-list
                        v-model="stateLoading"
                        :finished="stateFinished"
                >
                    <div class=" list-padding"
                         v-for="(item,idx) in dataList" :key="idx"
                    >
                        <div v-if="item.taskName" class="state-title">规范：{{item.taskName}}</div>
                        <div class="state-info">
                            <div>问题描述: {{item.question}}</div>
                            <div v-if="item.checkFile">检查图片: </div>
                            <van-uploader
                                    v-model="item.checkFile"
                                    v-if="item.checkFile"
                                    disabled
                                    :deletable="false"
                                    accept="image"
                                    :show-upload = false
                            />
                            <div>扣分: {{item.score}}</div>
                        </div>
                    </div>
                </van-list>
                <div class="button-box m-b-15">
                    <van-button plain type="primary" color="#3F7C53" size="small" @click="questionCancel">关闭</van-button>
                </div>
            </van-dialog>

    </div>

</template>

<script>
    import {getInspectList,getInspectDetail } from "@/utils/api";
    import HeaderSiteInfo from "../../components/siteInfo/headerSiteInfo";
    import common from '@/utils/common';
    import moment from "moment";
    export default {
        name: "inspectList.vue",
        components: {HeaderSiteInfo},
        data(){
            return{
                active: 0,
                siteInfo: {},
                url:'',
                masterInspectPic:'',
                isdisabled:false,
                show:false,
                showButton:true,
                onlychecked:false,
                minDate: new Date(2023,1,1),
                startDate:'',//开始日期 yyyy-MM-dd
                endDate:'',//结束日期 yyyy-MM-dd
                date:'',
                fileList:[],
                uploadZipImgList:[],
                fileImgUrls:[],
                defaultDate:[new Date((new Date()  - 1000 * 60 * 60 * 24 * 60)),new Date()] ,
                uniqueNo: common.getItem('uniqueNo')?common.getItem('uniqueNo'):this.$route.query.uniqueNo,
                checkSearch:{
                    userName:'',//运营人员姓名
                    checkDate:moment().format('YYYY/MM'),
                    checkType:'1',//检查类型
                    deptNo:'',
                },
                tabList: [
                    '检查结果',
                    '规范检查'
                ],
                pageIndex:1,//页码
                pageSize:10,
                typeText:'站点规范',
                checkDate:moment().format('YYYY/MM'),
                questionListShow:false,
                showState:false,
                deptNoTxt:'',
                questionSum:0,
                checkSum:0,
                score:0,
                checked: require('@/assets/img/site/checked.png'),
                unchecked: require('@/assets/img/site/unchecked.png'),
                showcheckDate:false,
                stateLoading:false,
                stateFinished:false,
                checkDetail:[],
                deptList:[],
                dataList:[],
                checkFile:[],
                isLoadingState:false,
                showDeptPicker: false,
                dateList:[
                    {text:moment().format('YYYY/MM'),value: moment().format('YYYY/MM')},
                    {text:moment().subtract(1,'months').format('YYYY/MM'),
                        value:moment().subtract(1,'months').format('YYYY/MM')},
                    {text:moment().subtract(2,'months').format('YYYY/MM'),
                        value:moment().subtract(2,'months').format('YYYY/MM')},
                    {text:moment().subtract(3,'months').format('YYYY/MM'),
                        value:moment().subtract(3,'months').format('YYYY/MM')},
                ],
                deptNames:{
                    text: 'deptName',
                    value: 'deptNo',
                    children: 'child',
                },


            }
        },
        methods:{
            questionCancel(){
                this.questionListShow = false;
            },
            questionDetail(id,type) {
                getInspectDetail({'taskNo':id,'checkType':type}).then(res=>{
                    console.log('result',res);
                    if(res.code===200 && res.data.businessCode===200){
                        this.questionListShow=true;
                        this.dataList = res.data.data;
                        console.log("dataList:",this.dataList)
                        for (let obj of this.dataList) {
                            if(obj.checkFilePath){
                                console.log("obj.checkFilePath:"+obj.checkFilePath)
                                obj.checkFile=[];
                                if(process.env.NODE_ENV == 'production'){
                                    let url = window.location.origin + '/file/' + obj.checkFilePath + '?Authorization=' + common.getItem('wtToken')
                                    console.log("uel:",url)
                                    obj.checkFile.push({url:url})
                                }else{
                                    let url = 'http://124.70.167.116:20081' + '/file/' +  obj.checkFilePath + '?Authorization=' + common.getItem('wtToken')
                                    console.log("uel:",url)
                                    obj.checkFile.push({url:url})
                                }
                                console.log("checkfile:",obj.checkFile)
                            }
                        }
                    }
                })
            },
            stateOnSearch(){
                this.checkDetail = []
                let data = {
                    stationNo:this.$route.query.stationNo,
                }
                getInspectList(data).then(res=>{
                    this.stateLoading = false;
                    console.log(res)
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.stateFinished = !res.data.data.hasNextPage;
                        this.checkDetail = res.data.data;
                        this.masterInspectPic = res.data.masterInspectPic;
                        var baseUrl = process.env.NODE_ENV != 'production' ? 'http://124.70.167.116:20081' + '/file/' : window.location.origin + '/file/'
                        var tokenUrl = '?Authorization=' + common.getItem('wtToken');
                        var url=baseUrl + this.masterInspectPic + tokenUrl;
                        console.log("url:"+url)
                        this.checkFile.push({url:url})
                        /*
                        if (this.pageIndex === 1) {
                            this.checkDetail = res.data.data.checkDetail;
                        } else {
                            this.checkDetail = this.checkDetail.concat(res.data.data.checkDetail);
                        }
                        if (!this.stateFinished) {
                            this.pageIndex++;
                        }
                         */
                    }
                })
            },
            stateOnLoad(){
                this.stateOnSearch();
            },
            stockOnRefresh(){
                this.isLoadingState = false
                this.checkDetail = []
                this.pageIndex = 1
                this.stateOnSearch()
            }
        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";
    .state-padding{
        padding: 18px 40px 15px 15px;
        position: relative;
        .state-title{
            font-size: 16px;
            font-weight: bold;
            color: #191F25;
        }
        .state-img{
            position: absolute;
            top:0;right: 0;
        }
        .state-info{
            font-size: 13px;
            font-weight: 500;
            color: #7E8185;
        }
        .state-num{
            font-size: 24px;
            font-weight: bold;
            color: #F25555;
            text-decoration: underline;
        }
        .state-reject{
            width:50%;
            color:#F25555;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }
        .state-confim{
            width:50%;
            color:#3F7C53;
            text-align: right;
            font-size: 14px;
            font-weight: 300;
        }
        .line{
            border-top:1px solid #F0F2F5;
        }

    }

    .list-padding{
        padding: 18px 40px 15px 15px;
        position: relative;
        .state-title{
            font-size: 10px;
            font-weight: bold;
            color: #191F25;
        }
        .qc-left {
            width: 2.8rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 0.2rem;
        }
        .state-info{
            font-size: 9px;
            font-weight: 400;
            color: #7E8185;
        }
        .state-reject{
            width:50%;
            color:#F25555;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }
        .state-confim{
            width:50%;
            color:#3F7C53;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }

    }

    .card{
        margin:10px;
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 10px 10px 5px #F5F6FA;
    }

    .img-icon {
        width: 0.28rem;
        height: 0.28rem;


        &:nth-child(1) {
            margin-left: 0;
        }
    }
    .card-upload {
        // margin: 0 0.3rem;
        height: 1.6rem;
        background: #fff;
        display: flex;
        align-items: center;
        padding: 0 0.2rem;
        overflow: hidden;
    }
    .content{
        margin-top: 20px;
    }
    .payImgs{
        padding: 22px 15px 20px 24px;
        background: #ffffff;
        label{
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9B9B9B;
            display: inline-block;
            vertical-align: top;
            margin-right: 55px;
        }
        p{
            padding-top: 8px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #9B9B9B;
        }

    }
    .siteInfoDiv{
        display: inline-block;
        vertical-align: middle;
        margin-left: 12px;

        h5{
            font-size:15px;
            font-family:PingFangSC-Medium,PingFang SC;
            font-weight:bold;
            color: #333333;
        }
        p{
            font-size:13px;
            font-family:PingFangSC-Regular,PingFang SC;
            font-weight:400;
            color: #999999;
            margin-top: 4px;

        }

    }


</style>
